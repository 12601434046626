type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

export const GTM_BODY_URL = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_BODY_URL
export const GTM_HEAD_URL = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_HEAD_URL
export const GTM_HEAD_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_HEAD_ID

/**
 * Sends a pageview event to the Google Tag Manager dataLayer.
 * If the dataLayer is not defined, logs the event to the console.
 *
 * @param url - The URL of the page to be tracked.
 */
export const pageview = (url: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    })
  } else {
    console.log({
      event: "pageview",
      page: url,
    })
  }
}