// Analytics.tsx
'use client';

import {
  GTM_BODY_URL,
  GTM_HEAD_ID,
  GTM_HEAD_URL,
  pageview,
} from './gtm';
import { usePathname, useSearchParams } from 'next/navigation';

import Script from 'next/script';
import { useEffect } from 'react';

/**
 * AnalyticsHeadScript component injects the Google Tag Manager (GTM) script into the head of the document.
 * 
 * This component uses the `usePathname` and `useSearchParams` hooks to track page views.
 * When the pathname changes, it triggers the `pageview` function with the new pathname.
 * 
 * The GTM script is inserted using the `Script` component with the `afterInteractive` strategy,
 * ensuring it runs after the page is interactive. The script is added using `dangerouslySetInnerHTML`.
 * 
 * @returns {JSX.Element} The GTM script element.
 */
export function AnalyticsHeadScript() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (pathname) {
      pageview(pathname);
    }
  }, [pathname, searchParams]);

  return (
    <Script
      strategy='beforeInteractive'
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src="${GTM_HEAD_URL}"+i;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_HEAD_ID}');`,
      }}
    />
  );
}

/**
 * Renders a noscript tag containing an iframe for Google Tag Manager (GTM).
 * This component is used to ensure that GTM still functions even if JavaScript is disabled in the user's browser.
 *
 * @returns {JSX.Element} A noscript element containing an iframe with the GTM body URL.
 */
export function AnalyticsBodyScript() {
  return (
    <noscript>
      <iframe
        src={GTM_BODY_URL}
        height='0'
        width='0'
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
}

export default { AnalyticsHeadScript, AnalyticsBodyScript };